import Vue from "vue";
import Vuex from "vuex";
import login from "../api/login"
import config from '../util/config'
import router from "../router";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        currentMenu: null,
        loginDialog: false,
        uuid: localStorage.getItem('uuid'),

        userInfo: localStorage.getItem('userInfo') != '' && localStorage.getItem('userInfo') != undefined ? JSON.parse(localStorage.getItem('userInfo')) : '',
        token: localStorage.getItem('token'),

    },
    mutations: {
        wxLogin(state, boot) { // 登录二维码
            state.loginDialog = boot;
            if (boot) {

                setTimeout(function() {
                    var obj = new WxLogin({
                        id: 'wx_login_id', // 需要显示的容器id
                        appid: config.Appid, // 公众号appid
                        scope: 'snsapi_login', // 网页默认即可
                        response_type: 'code',
                        // redirect_uri: encodeURIComponent(location.href.split("?")[0]), // 授权成功后回调的url
                        redirect_uri: encodeURIComponent('https://apparatus.ysxxlm.com/'), // 授权成功后回调的url
                    })
                }, 100)
            }
        },
        logOut(state) {
            localStorage.setItem('userInfo', '')
            localStorage.setItem('token', '');
            // localStorage.setItem('uuid', '')
            state.userInfo = ''
                // state.uuid = ''
            router.push('/');
        }
    },
    actions: {
        toLogin({ dispatch, state }, code) {
            if (localStorage.getItem('token') != '' && localStorage.getItem('token') != undefined) {
                return
            }
            login.pcLogin({ code }).then(res => {
                // dispatch('LxUuid')
                state.userInfo = res.data.data.user;
                state.token = res.data.data.token;
                localStorage.setItem('token', res.data.data.token);
                localStorage.setItem('userInfo', JSON.stringify(res.data.data.user));
            })

        },
        pcUuid({ dispatch, state }) {
            login.pcUuid().then(res => {
                state.uuid = res.data.data.uuid;
                localStorage.setItem('uuid', res.data.data.uuid);

                // dispatch('LxUuid', res.data.uuid);
            })
        },
        LxUuid({ dispatch, commit, state }, code) {
            let data = {
                uuid: state.uuid
            }
            login.LxUuid(data).then(res => {

                if (res.data.status == 0) {
                    setTimeout(() => {
                        state.userInfo = '';
                        state.uid = ''
                        localStorage.setItem('userInfo', '')
                        localStorage.setItem('uid', '')
                        dispatch('LxUuid', state.code);
                    }, 5000)
                } else {
                    state.userInfo = res.data;
                    state.uid = res.data.user.user_id
                    localStorage.setItem('userInfo', JSON.stringify(res.data))
                    localStorage.setItem('uid', res.data.user.user_id)
                    commit('wxLogin', false)
                }
            })
        }
    },
    modules: {},
});
