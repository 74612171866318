import Vue from "vue";
import VueRouter from "vue-router";


Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "index",
        meta: { title: '首页' },
        component: () =>
            import ("../views/index.vue"),
    },
    {
        path: "/instrumentlib",
        name: "instrumentlib",
        meta: { title: '器械库' },
        component: () =>
            import ("../views/instrumentlib.vue"),
        children: [{
                path: "/instrumentlib/",
                name: "instrumentlib",
                component: () =>
                    import ("../views/instrumentlib-index.vue"),
            },
            {
                path: "/instrumentlib/info/:id/:catId",
                name: "instrumentlibinfo",
                meta: { title: '器械库详情' },
                component: () =>
                    import ("../views/instrumentlib-info.vue"),
            },
            {
                path: "/instrumentlib/contrast/:type",
                name: "instrumentlibcontrast",
                meta: { title: '器械对比' },
                component: () =>
                    import ("../views/instrumentlib-contrast.vue"),
            },
            {
                path: "/instrumentlib/comcontrast/:type",
                name: "instrumentlibcomcontrast",
                meta: { title: '点评对比' },
                component: () =>
                    import ("../views/instrumentlib-contrast.vue"),
            },
        ]

    },
    {
        path: '/top',
        name: 'top',
        meta: { title: '排行榜' },
        component: () =>
            import ("../views/top.vue"),
        children: [{
                path: "/top/",
                name: 'topindex',

                component: () =>
                    import ("../views/top-index.vue"),
            },
            {
                path: "/top/info/:id",
                name: 'topinfo',
                meta: { title: '排行榜详情' },
                component: () =>
                    import ("../views/top-info.vue"),
            }
        ]
    },
    {
        path: '/check',
        name: 'check',
        meta: { title: '测评' },
        component: () =>
            import ("../views/check.vue"),
        children: [{
                path: '/check/',
                name: 'checkindex',

                component: () =>
                    import ("../views/check-index.vue"),
            },
            {
                path: '/check/product',
                name: 'checkproduct',
                meta: { title: '众测产品' },
                component: () =>
                    import ("../views/check-product.vue"),

            },
            {
                path: '/check/product/info/:id',
                name: 'checkproductinfo',
                meta: { title: '众测产品详情' },
                component: () =>
                    import ("../views/check-product-info.vue"),
            },
            {
                path: '/check/report',
                name: 'checkreport',
                meta: { title: '众测报告' },
                component: () =>
                    import ("../views/check-report.vue"),
            },
            {
                path: '/check/report/info/:id/:catId/:code',
                name: 'checkreportinfo',
                meta: { title: '众测报告详情', },
                component: () =>
                    import ("../views/check-report-info.vue"),
            },
            {
                path: '/check/apply/:id',
                name: 'checkapply',
                meta: { title: '众测申请' },
                component: () =>
                    import ("../views/check-apply.vue"),
            },
        ]
    },
    {
        path: '/info',
        name: 'info',
        meta: { title: '资讯' },
        component: () =>
            import ("../views/info.vue"),
        children: [{
                path: '/info/',
                name: 'infoindex',

                component: () =>
                    import ("../views/info-index.vue"),
            },
            {
                path: '/info/textinfo/:id',
                name: 'textinfo',
                meta: { title: '文章详情' },
                component: () =>
                    import ("../views/info-textinfo.vue"),
            }
        ]
    },
    {
        path: '/comment',
        name: 'comment',
        meta: { title: '点评', params: "1" },
        component: () =>
            import ("../views/complaint.vue"),
        children: [{
                path: '/comment/:type',
                name: 'complaintindex',

                component: () =>
                    import ("../views/complaint-index.vue"),
            },
            {
                path: '/comment/info/:type/:code/:id',
                name: 'commentInfo',
                meta: { title: '点评详情', },
                component: () =>
                    import ("../views/complaint-info.vue"),
            },
            {
                path: '/comment/commit/:type/:id',
                name: 'commentCommit',
                meta: { title: '我要点评', },
                component: () =>
                    import ("../views/complaint-commit.vue"),
            }
        ]
    },
    {
        path: '/complaint',
        name: 'complaint',
        meta: { title: '投诉', params: "2" },
        component: () =>
            import ("../views/complaint.vue"),
        children: [{
                path: '/complaint/:type',
                name: 'complaintindex',

                component: () =>
                    import ("../views/complaint-index.vue"),
            },
            {
                path: '/complaint/info/:type/:code/:id',
                name: 'complaintinfo',
                meta: { title: '投诉详情' },
                component: () =>
                    import ("../views/complaint-info.vue"),
            },
            {
                path: '/complaint/commit/:type/:id',
                name: 'complaintCommit',
                meta: { title: '我要投诉' },
                component: () =>
                    import ("../views/complaint-commit.vue"),
            }
        ]
    },
	{
		path: '*',
		component: () => import('@/views/404'),
		meta: {
			title: '404'
		}
	}
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior: () => ({ y: 0 }),
    routes,
});

router.beforeEach((to, from, next) => {
    next();
    console.log(to.matched)
})

export default router;