<template>
  <div class="navContainer">
    <div class="nav">
      <div class="nav_logo">
        <div class="nav_logo-logo">
          <img src="../assets/logo.png" alt="" />
        </div>

        <div class="nav_logo-text">泌尿男科器械平台</div>
      </div>
      <div class="nav_list">
        <el-menu
          mode="horizontal"
          text-color="#0394FF"
          active-text-color="#0394FF"
          :default-active="routerPath"
          router
        >
          <fragment v-for="item in menuList" :key="item.name">
            <el-menu-item :index="item.path" v-show="!item.children">
              {{ item.name }}
            </el-menu-item>
            <el-submenu v-if="item.children" :index="item.path">
              <template slot="title">{{ item.name }}</template>
              <el-menu-item
                class="subnav"
                v-for="citem in item.children"
                :key="citem.name"
                :index="citem.path"
              >
                {{ citem.name }}
              </el-menu-item>
            </el-submenu>
          </fragment>

          <!-- <el-submenu> </el-submenu> -->
        </el-menu>
      </div>
      <div class="nav_r">

        <div class="nav_search">
          <el-input class="nav_search-input"></el-input>
          <el-button class="nav_search-btn" round>搜索</el-button>
        </div>

        <div class="nav_user" v-if="!userInfo.memberIcon">
          <el-button type="text" @click="wxlogin" icon="el-icon-user-solid"
            >登录</el-button
          >
          <el-button round>注册</el-button>
        </div>

        <div class="nav_icon" v-if="userInfo.memberIcon">
          <el-avatar :size="40" :src="userInfo.memberIcon"></el-avatar>
          <div class="username">
            <span>{{ userInfo.memberName }} </span>

          </div>
          <i class="el-icon-arrow-down"></i>
          <div class="loginout">
            <span class="box">
              <span @click="loginOut">退出登录</span>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="navbred">
      <div class="nav_breadcrumb" v-if="currentPath.length != 0">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item
            v-for="(list, index) in currentPath"
            :key="index"
            :to="{ path: list.path }"
            >{{ list.meta.title }}</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
    </div>
  </div>
</template>
<script>
import { Fragment } from "vue-fragment";
export default {
  data() {
    return {
      menuList: [
        {
          path: "/",
          name: "首页",
        },
        {
          path: "/instrumentlib",
          name: "器械库",
        },
        {
          path: "/top",
          name: "排行榜",
        },
        {
          path: "/info",
          name: "资讯",
        },
        {
          path: "/check",
          name: "测评",
        },
        {
          path: "/comment/1",
          name: "点评",
        },
        {
          path: "/complaint/2",
          name: "投诉",
        },
      ],
      currentPath: [],
      indexBreadcrumbs: "",
      routerTitle: "",

    };
  },
  components: { Fragment },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    },
    routerPath(){
      let str = this.$route.path

      let path = str.indexOf("/",str.indexOf("/")+1 ) == -1? this.$route.path:str.substring(0,str.indexOf("/",str.indexOf("/")+1))

      if(path == '/comment'){
        path = path+'/1'
      }else if(path == '/complaint'){
        path = path+'/2'
      }
      return path;
    }
  },
  watch: {
    $route(to, from) {
      if (this.routerTitle != "") {
        this.$route.matched[this.$route.matched.length - 1].meta = {
          title: this.routerTitle,
        };
      }

      this.breadcrumbList();
      this.routerTitle = "";
    },
  },
  created(){

  },
  mounted() {
    this.$bus.$on("meta", (val) => {
      this.routerTitle = val;
    });
  },
  methods: {
    breadcrumbList() {
      this.currentPath = this.$route.matched.filter((item) => {
        if (item.meta.title != "首页" && item.meta.title) {
          return item.meta;
        }
      });
      for (const i in this.currentPath) {
        let item = this.currentPath[i];
        if (item.meta.parent) {
          let data = {
            meta: {
              title: item.meta.parent,
            },
          };
          this.currentPath.splice(i, 0, data);
        }
        if (item.meta.params) {
          let reg = /([^\/]*\/[^\/]*)\/.*/;
          let data = item.path.replace(reg, "$1");
          item.path = `${data}/${item.meta.params}`;
        }
      }
    },
    wxlogin() {
      this.$store.commit("wxLogin", true);
    },
    loginOut(){
      this.userInfo = ''
      this.$store.commit('logOut', false)
    }
  },

  destroyed() {
    this.$bus.$off("meta");
  },
};
</script>
<style lang="scss">
.nav_list {
  .el-menu {
    border-bottom: 0 !important;
  }
}
.nav_breadcrumb {
  .el-breadcrumb {
    line-height: 85px;
  }
  .el-breadcrumb__inner {
    &.is-link {
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400 !important;
      color: #666666 !important;
    }
  }
}
.nav_search-input {
  .el-input__inner {
    width: 179px;
    height: 45px;
    border-radius: 30px;
    padding-right: 50px;
  }
}
.nav_search-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;

  &.el-button {
    font-size: 14px;
    background: #0494ff;
    color: #ffffff;
    font-family: Pridi-Regular, Pridi;
    font-weight: 400;
    font-size: 19px;
  }

  &.is-round {
    padding: 5px 8px !important;
  }
}
.el-menu-item {
  &.subnav {
    font-size: 19px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666 !important;
    height: 45px !important;
    line-height: 45px !important;
  }
}
.nav_list {
  .el-menu {
    display: flex;
    height: 85px;
    .el-menu-item {
      // padding: 0 34.5px;
      height: 85px;
      line-height: 85px;
      font-size: 24px;

      font-family: PingFang SC-Regular, PingFang SC;
      font-style: normal;
      font-weight: normal;

      text-align: center;
      color: #0394ff;
      border-bottom-color: transparent !important;
      position: relative;
      flex-grow: 1;
      &.is-active {
        &::after {
          content: "";
          display: block;
          width: 48px;
          height: 8px;
          background: #0092ff;
          position: absolute;
          bottom: 5px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .el-submenu {
      position: relative;
      height: 85px;
      &.is-active {
        &::after {
          content: "";
          display: block;
          width: 53px;
          height: 8px;
          background: #0092ff;
          position: absolute;
          bottom: 5px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .el-submenu__title {
      border-bottom-color: transparent !important;
      height: 85px !important;
      line-height: 85px !important;

      font-size: 24px;

      font-family: PingFang SC-Regular, PingFang SC;
      font-style: normal;
      font-weight: normal;
    }
    .el-menu--horizontal {
      .el-menu-item {
        font-size: 19px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666 !important;
      }
    }
  }
}
.nav_user {
  .el-button {
    // width: 50px;
    // height: 28px;
    // padding: 0 !important;
    color: #72ccff;
    font-size: 19px;
    margin-right: 0px;
    &.is-round {
      padding: 5px 8px;
      border-color: #72ccff;
    }
  }
}
</style>
<style lang="scss" scoped>
.navContainer {
  position: fixed;
  top: 0;
  width: 100%;
  min-width: 700px;
  z-index: 999;
  .navbred {
    background: #fbfbfb;
  }
  .nav_breadcrumb {
    height: 85px;
    width: 1243px;
    margin: 0 auto;
  }
}

.nav {
  line-height: 85px;
  height: 85px;
  display: flex;
  justify-content: space-between;
  font-family: PingFang Regular;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 3px 16px 1px rgba(0, 0, 0, 0.05);

  .nav_logo {
    color: #0595ff;
    font-size: 25px;
    min-width: 242px;
    // padding: 0 37px;
    // margin-right: 193px;
    padding-left: 37px;
    display: flex;
    justify-content: space-between;
    .nav_logo-logo {
      display: flex;
      align-items: center;
      // margin-right: 28px;
    }
    .nav_logo-text {
      font-family: PingFang SC-Regular, PingFang SC;
      font-style: normal;
      font-weight: normal;
    }
    img {
      width: 31px;
    }
  }
  .nav_list {
    min-width: 884px;
  }
  .nav_r {
    display: flex;
    .nav_search {
      position: relative;
      margin-right: 47px;
      // margin-left: 90.5px;
      .nav_search-input {
      }
      .nav_search-btn {
      }
    }
    .nav_user {
      width: 150px;
      padding-right: 25px;
      font-size: 14px;
    }
    .nav_icon {
      width: 150px;
      padding-right: 25px;
      font-size: 14px;
      position: relative;
      cursor: pointer;
      z-index: 1001;
      &:hover{
        .loginout{
          display: block;
        }
      }
      span,i{
        vertical-align: middle;
      }
      .username {
        display: inline;
        margin-left: 12px;
        margin-right: 3px;


      }
      .loginout{
        position: absolute;
        left: 28px;
        top: 36px;
        z-index: 1000;
        display: none;

        .box{
          padding: 12px 24px;
          background: #fff;
          border-radius: 4px;
          box-shadow: 0px 5px 11px 1px rgba(0,0,0,0.07000000029802322);
          font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
          &::after{

          }
        }
      }
    }
  }
}
</style>
