import axios from 'axios';


const service = axios.create({
    baseURL: '/api/',
    timeout: 8000 // 请求超时时间
})

service.interceptors.request.use(
    config => {
        if (localStorage.getItem('token')) {
            config.headers["Authorization"] = localStorage.getItem("token");

        }
        return config;
    }
);

service.interceptors.response.use();

export default service
