<template>
  <div id="app">
    <navbar></navbar>
    <router-view />
    <footers></footers>
    <app-wxlogin-dialog />
  </div>
</template>

<script>
import appWxloginDialog from "./components/app-wxlogin-dialog"; // 头部
import navbar from "./views/nav.vue";
import footers from "./views/footer.vue";
export default {
  name: "app",
  components: {
    navbar,
    footers,
    appWxloginDialog,
  },
  watch: {
    $route(to, from) { 
      if (to.query.code) {
        let code = to.query.code;
        let msg = code;
        if (typeof code != "string") {
          msg = code[code.length - 1];
        }
        this.$store.dispatch("toLogin", msg);
      }
    },
  },
  computed: {
    token(){
      return this.$store.state.token;
    }
  },
  created() {
    // if (!this.token || this.token == undefined) {
    //   this.$store.dispatch("pcUuid");
    // } 
  },
  
};
</script>

<style lang="scss">
@import url("//at.alicdn.com/t/font_2878376_i3ztg8f8ve.css");
* {
  margin: 0;
  padding: 0;
}
html {
  height: 100%;
}
body {
  background: #fbfbfb;
  font-size: 16px;
  height: calc(100% - 170px);
}
#app {
  // min-height:calc(100%)
}
.modal-txt {
  text-align: center;
  img {
    width: 200px;
  }
  p {
    color: #999;
    font-size: 12px;
  }
}
</style>
