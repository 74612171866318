import request from '../util/request';

const apis = {
    pcUuid() {
        return request({
            url: `/V1/pcUuid`,
            method: 'post',
        })
    },
    LxUuid(data) {
        return request({
            url: `/V1/LxUuid`,
            method: 'post',
            data
        })
    },
    pcLogin(data) {
        return request({
            url: `/V1/pcLogin`,
            method: 'post',
            data
        })
    },
    login() {

    }
}

export default apis