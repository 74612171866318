import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import "./plugins/element.js";
// import 'element-ui/lib/theme-chalk/index.css';
import ElementUi from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/base.scss';
import time from './util/time.js'

import macy from "macy";
import { VueMasonryPlugin } from 'vue-masonry';

import waterfall from 'vue-waterfall2'

Vue.config.productionTip = false;
Vue.prototype.Macy = macy;
Vue.prototype.Time = time;
// Vue.prototype.Masonry = VueMasonryPlugin;
Vue.use(VueMasonryPlugin)
Vue.use(waterfall)

Vue.use(ElementUi)
Vue.prototype.$bus = new Vue();

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
